<template>
	<div class="header-box">
		<div class="header">
			<div>
				<div @click="getHome" class="logo-box">
					<img class="logo" :src="homeDate.logo_url" alt="" >
					<div class="logo-title-box">
						<p>北京市体育竞赛管理和国际交流中心</p>
						<p class="el">Beijing Sports Competitions Administration and International Exchange Center</p>
					</div>
				</div>
			</div>
			<div class="searchfor">
				<el-input class="input-with-select" v-model="keyword">
					<el-button slot="append" icon="el-icon-search" @click="getsearch"></el-button>
				</el-input>
			</div>
			<div class="but-box">
				<div class="but">
					<div><a href="/">首 页｜</a></div>
					<div><a href="/login">用户中心｜</a></div>
					<div><a href="/register">账号注册</a></div>
				</div>
			</div>
		</div>
		<div class="logon">
			<div class="logon-box">
				<div class="form">
					<div class="user thatperson" @click="thatperson">
						个人用户
					</div>
					<div class="user group" @click="group">
						团体用户
					</div>
				</div>
				<div class="position-box">
					<div class="position" v-if="person"></div>
					<div class="position toposition" v-if="grp"></div> <!-- v-if="grp" -->
					<div v-else class="position2"></div>
				</div>
				<div class="admin" v-if="person">账号登录</div>
				<div class="int">
					<img src="../assets/image/admin.png" alt="">
					<input type="text" placeholder="请输入手机号" v-model="username">
				</div>
				<div class="int pass">
					<img src="../assets/image/pass.png" alt="">
					<el-input v-model="password" show-password placeholder="请输入登录密码" class="custom-input"></el-input>
				</div>
				<div class="button" @click="logon">
					登录
				</div>
				<div class="butt-box">
					<div class="find" @click="download">
						帮助我
					</div>
					<div class="find register">
						<a href="/changePassword" style="color: #fff;">找回密码</a>
					</div>
					<div class="find register">
						<a href="/register" style="color: #fff;">注册账号</a>
					</div>
				</div>
			</div>
			<img src="../assets/image/logon.png" alt="" class="back">
		</div>
	</div>
</template>
<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				person: true,
				grp: false,
				username: '',
				password: '',
				homeDate:{},
				keyword:'',
				userType:'user_type_01'
			}
		},
		created() {
			if(this.$route.query.person == 'true'){
				this.person = true
			}
			if(this.$route.query.person == 'false'){
				this.person = false
				this.userType = 'user_type_02'
			}
			if(this.$route.query.grp == 'true'){
				this.grp = true
				this.userType = 'user_type_02'
			}
			if(this.$route.query.grp == 'false'){
				this.grp = false
			}
			this.$store.commit("EXIT_STATIONID",'1005012')
			this.findStationById()
			
			if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
			  document.documentElement.className += ' ie-browser';
			}
		},
		methods: {
			findStationById() {
				this.$api.findStationById(this.$store.state.stationId).then(res => {
					this.homeDate = res.data.data
				})
			},
			thatperson() {
				this.person = true
				this.grp = false
				this.userType = 'user_type_01'
			},
			group() {
				this.person = false
				this.grp = true
				this.userType = 'user_type_02'
			},
			logon() {
				if (this.username == '') {
					return this.$message.error('请输入账号')
				}
				if (this.password == '') {
					return this.$message.error('请输入密码')
				}
				let data = {
					username: this.$encrypt(this.username),
					password: this.$encrypt(this.password),
					userType: this.$encrypt(this.userType)
				}
				this.$api.checkLogin(data).then(res => {
					let data = {
						res:res.data.data.result,
						index:2
					}
					this.$store.commit("SET_TOKEN",data)
					this.username = ''
					this.password = ''
				})
			},
			getHome(){
				this.$router.push({
					path: '/',
					query: {
						refresh: new Date().getTime()
					},
				})
			},
			getsearch(){
				this.$router.push({
					path: '/search',
					query: {
						keyword: this.keyword,
						refresh: new Date().getTime()
					},
				})
			},
			download() {
			  const a = document.createElement('a')
			  a.href = '/static/账号注册操作手册.pdf'
			  a.download = '账号注册操作手册.pdf'
			  a.click()
			},
		}
	}
</script>
<!--[if IE]>
<style lang="scss" scoped>
 .logon-box{
 	width: 1200PX;
 	margin: 0 auto;
 	display: flex;
 	justify-content: ;
 }
</style>
<![endif]-->
<style lang="scss" scoped>
	body {
		margin: 0;
		padding: 0;
	}

	.header {
		display: flex;
		width: 1200PX;
		margin: 54PX auto 25PX auto;
	}

	.logo-box{
		display: flex;
	}
	.logo {
		width: 76PX;
		height: 72PX;
		cursor: pointer;
	}
	.logo-title-box{
		font-family: MicrosoftYaHei;
		margin-left: 10PX;
		cursor: pointer;
		p{
			font-size: 28PX;
			margin-top:4PX;
			letter-spacing: 1.3PX;
		}
		.el{
			font-size: 12PX;
			margin-top: 6PX;
			letter-spacing: 0PX;
		}
	}

	.img {
		image-rendering: -moz-crisp-edges;
		image-rendering: -o-crisp-edges;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: crisp-edges;
		-ms-interpolation-mode: nearest-neighbor;
	}

	.nameTitle {
		width: 564PX;
		height: 73PX;
	}

	.searchfor {
		width: 227PX;
		height: 34PX;
		margin-left: 180PX;
		margin-top: 18PX;
	}

	.searchfor ::v-deep .el-input__inner {
		height: 34PX;
		border: 2PX solid #949494;
	}

	.searchfor ::v-deep .el-input-group__append {
		border: 1PX solid #949494;
		color: #fff;
		background-color: #949494;
	}

	.searinput {
		width: 227PX;
		height: 34PX;
		border: 2PX solid #949494;
		border-radius: 7PX;
		padding-left: 10PX;
		font-size: 15PX;
	}

	.but-box {
		display: flex;
		// width: 170PX;
		height: 18PX;
		font-size: 16PX;
		font-family: MicrosoftYaHei;
		font-weight: 400;
		color: #333333;
		margin-left: 34PX;
		margin-top: 26PX;
	}

	.but-box .but {
		display: flex;
		cursor: pointer;
	}

	.logon {
		width: 100%;
		height: calc(100vh - 297PX);
		min-height: 1000PX;
	}
	
	.logon-box{
		width: 1200PX;
		margin: 0 auto;
		display: flex;
		justify-content: end;
	}

	.logon .back {
		width: 100%;
		height: 100%;
	}

	.form {
		width: 533PX;
		position: absolute;
		margin-top: 119PX;
		display: flex;
		cursor: pointer;
		justify-content: center;
	}

	.user {
		font-size: 32PX;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 4PX;
	}

	.thatperson {
		margin-right: 138PX;
	}

	.position-box{
		width: 533PX;
		display: flex;
		margin-top: 179PX;
		position: absolute;
		justify-content: flex-end;
		
		.position{
			width: 216PX;
			height: 10PX;
			background: #FFFFFF;
			margin-right: 50PX;
		}
		.toposition {
			margin-right: 25PX;
		}
		.position2{
			width: 216PX;
			height: 10PX;
			margin-right: 20PX;
		}
	}

	

	.admin {
		position: absolute;
		font-size: 25PX;
		font-weight: 500;
		color: #FFFFFF;
		margin-top: 240PX;
		cursor: pointer;
		width: 1200PX;
		display: flex;
		justify-content: end;
		margin-right: 430PX;
	}

	.int {
		width: 533PX;
		height: 76PX;
		background: #fff;
		border-radius: 8PX;
		position: absolute;
		margin-top: 294PX;
	}

	.int img {
		width: 20PX;
		height: 21PX;
		margin: 27PX 0 0 53PX;
	}

	.int input {
		width: calc(100% - 105PX);
		height: 76PX;
		background: #fff;
		border-radius: 8PX;
		font-size: 20PX;
		position: absolute;
		margin-left: 32PX;
		padding: 0 15PX
	}

	.int .el-input {
		width: calc(100% - 105PX);
		height: 76PX;
		background: #fff;
		border-radius: 8PX;
		font-size: 20PX;
		position: absolute;
		margin-left: 32PX;
	}

	.int ::v-deep .el-input__inner {
		height: 76PX;
		background: #fff;
		font-size: 20PX;
		position: absolute;
		border: none;
		color: #000;
	}

	.custom-input ::placeholder {
		color: #164B92;
		/* 设置提示文字的颜色 */
	}

	.int input::input-placeholder {
		color: #164B92;
	}

	.int input::-webkit-input-placeholder {
		color: #164B92;
	}

	.int input::-moz-placeholder {
		color: #164B92;
	}

	.int input::-moz-placeholder {
		color: #164B92;
	}

	.int input::-ms-input-placeholder {
		color: #164B92;
	}


	.pass {
		margin-top: 395PX;
	}

	.button {
		width: 531PX;
		height: 81PX;
		background: #0047A5;
		border-radius: 8PX;
		position: absolute;
		margin-top: 505PX;
		font-size: 32PX;
		font-weight: bold;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		letter-spacing: 10PX;
	}
	
	.butt-box{
		width: 531PX;
		display: flex;
		position: absolute;
		margin-top: 614PX;
		justify-content: flex-end
	}
	.find{
		font-weight: 400;
		color: #FFFFFF;
		line-height: 4PX;
		cursor: pointer;
		font-size: 20PX;
	}
	
	.register {
		margin-left: 25PX;
	}

	::v-deep .el-icon-search {
		font-size: 22PX;
		display: flex;
		justify-content: center;
	}
	
	.ie-browser .input-with-select{ 
		::v-deep .el-icon-search{
			margin-left: -11PX;
			margin-top: 2PX;
		}
		::v-deep .el-input-group__append{
			border: 2.3PX solid #949494;
		}
		::v-deep .el-icon-search {
			font-size: 22PX;
			display: block;
		}
	}
	
	.input-with-select {
		font-size: 15PX;
	}
	
	.searchfor ::v-deep .el-button {
		width: 40PX;
	}
	::v-deep .el-input__inner{
		border-top-left-radius: 7PX;
		border-bottom-left-radius: 7PX;
	}
	.searchfor ::v-deep .el-input-group__append{
		border-top-right-radius: 7PX;
		border-bottom-right-radius: 7PX;
	}
	
	
	.ie-browser .logon-box{
		width: 0PX;
		margin: 0 auto;
		display: flex;
		justify-content: flex-end;
	}
	
	.ie-browser .admin {
		position: absolute;
		font-size: 25PX;
		font-weight: 500;
		color: #FFFFFF;
		margin-top: 240PX;
		cursor: pointer;
		width: 200PX;
		display: flex;
		justify-content: end;
		margin-right: 430PX;
	}
	
</style>